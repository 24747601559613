import { uuid } from "short-uuid";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useDialog } from "./dialog";

import type { Account } from "API";
import useDatalist from "hooks/datalist";
import { useAuth } from "contexts/auth";
import { useAlerts } from "contexts/alerts";
import { download, parseImportTemplate } from "utils/masterData/template";
interface AccountsContextValue {
  loading: boolean;
  accounts: Account[];
  loadNext: () => void;
  refetch: (variables: any) => Promise<void>;
  create: (variables: any) => Promise<void>;
  update: (variables: any) => Promise<void>;
  remove: (ids: string[]) => Promise<void>;
  downloadImportTemplate: () => Promise<void>;
  importTemplates: () => Promise<void>;
}

const AccountsContext = createContext<AccountsContextValue>({
  accounts: [],
  loading: false,
  loadNext: () => null,
  refetch: () => Promise.resolve(),
  create: () => Promise.resolve(),
  update: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  downloadImportTemplate: () => Promise.resolve(), // この行を追加
  importTemplates: () => Promise.resolve(), // この行を追加
});
export const AccountsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const { open } = useDialog();
  const { addAlert } = useAlerts();
  const {
    data,
    create,
    update,
    remove,
    loading,
    loadNext,
    refetch,
    nextToken,
    appendNext,
  } = useDatalist({
    query: "accountsByTenantId",
    variables: {
      tenantId: user?.tenantId,
    },
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);

  const createAccount = async (variables: any) => {
    await create("createAccount", {
      tenantId: user?.tenantId,
      ...variables,
    });
    addAlert({ message: "取引先を作成しました", severity: "success" });
  };

  const updateAccount = async (variables: any) => {
    await update("updateAccount", {
      tenantId: user?.tenantId,
      ...variables,
    });
    addAlert({ message: "取引先を更新しました", severity: "success" });
  };

  const removeAccount = async (ids: string[]) => {
    open({
      title: "取引先を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        const promises = ids.map((id) =>
          remove("deleteAccount", {
            id,
          })
        );
        await Promise.all(promises);

        addAlert({ message: "取引先を削除しました", severity: "success" });
      },
    });
  };

  // 仕入先情報import用templateのダウンロード状態用
  const [error, setError] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const [exportingTemplates, setExportingTemplates] = useState<string[]>([]);
  const downloadImportTemplate = async () => {
    setError(false);
    const exportId = uuid(); // uuidv4を使用するように変更
    setExporting(true);
    setExportingTemplates([...exportingTemplates, exportId]);
    try {
      // データベースから取引先情報を取得
      const accounts = data || [];

      // 取引先情報をテンプレートの形式に変換
      const templateData = accounts.map((account: Account) => {
        const {
          id,
          name,
          site,
          abbreviation,
          billingState,
          billingCity,
          billingStreet,
          phone,
          fax,
          accountCode,
          cutoffDateText,
          paymentMonthText,
          paymentDateText,
          note,
          active,
        } = account;
        return {
          "取引先id(編集不可)": id,
          "取引先名(必須)": name,
          部門名: site,
          取引先部門: site,
          略称: abbreviation,
          都道府県: billingState,
          市区郡: billingCity,
          "町・番地": billingStreet,
          電話番号: phone,
          FAX: fax,
          取引先コード: accountCode,
          締日: cutoffDateText,
          支払月: paymentMonthText,
          支払日: paymentDateText,
          備考: note,
          取引先外部連携id: accountCode,
          有効: active === false ? "いいえ" : "はい",
        };
      });

      // テンプレートをダウンロード
      await download(templateData, "accounts");
    } catch (err: any) {
      setError(true);
      if (typeof err === "string") {
        addAlert({
          message: `取引先読込用テンプレートが出力されませんでした: ${err}`,
          severity: "error",
        });
      } else if (err instanceof Error) {
        addAlert({
          message: `取引先読込用テンプレートが出力されませんでした: ${err.message}`,
          severity: "error",
        });
      } else {
        addAlert({
          message: `取引先読込用テンプレートが出力されませんでした`,
          severity: "error",
        });
      }
    } finally {
      setExportingTemplates(exportingTemplates.filter((e) => e !== exportId));
    }
  };

  // templateのimport状態用
  const [importError, setImportError] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [importingTemplates, setImportingTemplates] = useState<string[]>([]);
  const importTemplates = async () => {
    // インポートエラーフラグをリセット
    setImportError(false);
    // インポートIDを生成
    const importId = uuid();
    // インポート中フラグを設定
    setImporting(true);
    // インポート中のテンプレートIDを追加
    setImportingTemplates([...importingTemplates, importId]);

    // ファイル選択用のinput要素を作成
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.multiple = true;
    input.onchange = async (event: Event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files) {
        // 選択されたファイルを順次処理
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          // ファイルの拡張子が.xlsxの場合のみ処理
          if (file && file.name.endsWith(".xlsx")) {
            try {
              // Excelファイルをパースし、GraphQLの入力形式に変換
              const importedInput = await parseImportTemplate(file, "accounts");
              // 変換された入力データを使用して取引先を作成または更新
              const missingValues = importedInput.filter((row) => !row["name"]);
              if (missingValues.length > 0) {
                throw new Error(
                  "すべての行に対して、取引先名に値が入っている必要があります。"
                );
              }
              for (let j = 0; j < importedInput.length; j++) {
                const { id, active, ...rest } = importedInput[j];
                const existingAccount = data?.find(
                  (account: Account) => account.id === id
                );
                if (existingAccount) {
                  // 同じidの取引先が既に存在する場合はupdate
                  await update("updateAccount", {
                    id,
                    active: active === "はい" ? true : false,
                    ...rest,
                    tenantId: user?.tenantId,
                  });
                } else {
                  // idがnullの場合は新しいidを生成してcreate
                  const newId = uuid();
                  await create("createAccount", {
                    id: newId,
                    active: active === "はい" ? true : false,
                    ...rest,
                    tenantId: user?.tenantId,
                  });
                }
              }
            } catch (err: any) {
              // エラー処理
              setImportError(true);
              if (typeof err === "string") {
                addAlert({
                  message: `取引先読込用テンプレートがインポートできませんでした: ${err}`,
                  severity: "error",
                });
              } else if (err instanceof Error) {
                addAlert({
                  message: `取引先読込用テンプレートがインポートできませんでした: ${err.message}`,
                  severity: "error",
                });
              } else {
                addAlert({
                  message: `取引先読込用テンプレートがインポートできませんでした`,
                  severity: "error",
                });
              }
            }
          } else {
            // ファイルの拡張子が.xlsx以外の場合はエラー
            setImportError(true);
            addAlert({
              message: `取引先読込用テンプレートがインポートできませんでした`,
              severity: "error",
            });
          }
        }
      }
      // インポート中のテンプレートIDを削除
      setImportingTemplates(importingTemplates.filter((e) => e !== importId));
      // インポート中フラグをリセット
      setImporting(false);
    };
    // ファイル選択ダイアログを表示
    input.click();
  };
  return (
    <AccountsContext.Provider
      value={{
        accounts: data || [],
        loading,
        loadNext,
        create: createAccount,
        update: updateAccount,
        remove: removeAccount,
        refetch: async (customVariables) =>
          await refetch({
            tenantId: user?.tenantId,
            ...customVariables,
          }),
        downloadImportTemplate,
        importTemplates, // この行を追加
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export const useAccounts = () => {
  const accountsContext = useContext(AccountsContext);

  if (accountsContext === undefined) {
    throw new Error("useAccounts must be within AuthProvider");
  }

  return accountsContext;
};
