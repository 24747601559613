import Link from "next/link";
import { Button } from "@mui/material";

import { IPermission } from "utils/permission";

export default function AccessGate({
  children,
  permission,
  message,
}: {
  children: React.ReactNode;
  permission: IPermission;
  message?: string;
}) {
  if (permission.canRead) {
    return <>{children}</>;
  }

  return (
    <div
      role="alert"
      className="w-full mt-10 flex flex-col justify-center items-center"
    >
      <h1 className="text-3xl font-bold mb-3">アクセスエラー</h1>
      <p className="mb-4">{message}</p>
      <Link href="/">
        <Button variant="outlined">ホームへ戻る</Button>
      </Link>
    </div>
  );
}
