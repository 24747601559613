// import用のテンプレを保存します
import moment from "moment";

import {
  createImportTemplate,
  importTemplate2GraphQLInput,
} from "../render/xlsx";

import { GraphQLInput } from "hooks/datalist";
import { downloadFile } from "utils/files";
export async function download(templateData: any[], type: string) {
  try {
    let fileName = "";
    switch (type) {
      case "accounts":
        fileName = `取引先読込用テンプレート_${moment().format(
          "YYYYMMDDHHmmss"
        )}`;
        break;
      case "contacts":
        fileName = `取引先責任者読込用テンプレート_${moment().format(
          "YYYYMMDDHHmmss"
        )}`;
        break;
      case "vendors":
        fileName = `仕入先読込用テンプレート_${moment().format(
          "YYYYMMDDHHmmss"
        )}`;
        break;
      default:
        // typeが上記以外の場合のファイル名を設定
        fileName = `その他のテンプレート_${moment().format("YYYYMMDDHHmmss")}`;
        break;
    }

    // ファイルの中身作成
    const blob = await createImportTemplate(templateData, type);
    await downloadFile(fileName, blob);
  } catch (e: any) {
    throw Error(e);
  }
}

// import用のテンプレを保存できるように変換します
export async function parseImportTemplate(
  file: File,
  type: string
): Promise<GraphQLInput[]> {
  const content = await file.arrayBuffer();
  // ファイルの内容をGraphQLInputに変換
  const lines = await importTemplate2GraphQLInput(content, type);
  return lines;
}
