import { CreateAccountInput, CreateContactInput, CreateVendorInput } from "API";

// importテンプレの列定義を持つ
export const _masterInfo_accounts: Partial<{ name: string }>[] = [
  { name: "取引先id(編集不可)" },
  { name: "取引先名(必須)" },
  { name: "部門名" },
  { name: "略称" },
  { name: "郵便番号" },
  { name: "都道府県" },
  { name: "市区郡" },
  { name: "町・番地" },
  { name: "電話番号" },
  { name: "FAX" },
  { name: "取引先コード" },
  { name: "締日" },
  { name: "支払月" },
  { name: "支払日" },
  { name: "備考" },
  { name: "取引先外部連携id" },
  { name: "有効" },
];

// 日本語から英語へのフィールド名のマッピング
export const _fieldNameMap_accounts: {
  [key: string]: keyof CreateAccountInput;
} = {
  "取引先id(編集不可)": "id",
  "取引先名(必須)": "name", // ここを変更しました
  部門名: "site",
  略称: "abbreviation",
  都道府県: "billingState",
  郵便番号: "billingPostalCode",
  市区郡: "billingCity",
  "町・番地": "billingStreet",
  電話番号: "phone",
  FAX: "fax",
  取引先コード: "accountCode",
  締日: "cutoffDateText",
  支払月: "paymentMonthText",
  支払日: "paymentDateText",
  備考: "note",
  取引先外部連携id: "accountCode",
  有効: "active",
};

// importテンプレの列定義を持つ
export const _masterInfo_contacts: Partial<{ name: string }>[] = [
  { name: "取引先責任者id(編集不可)" },
  { name: "姓(必須)" },
  { name: "名" },
  { name: "取引先id(必須)" },
  { name: "取引先名(参照)" },
  { name: "部署" },
  { name: "Eメール" },
  { name: "電話番号" },
  { name: "携帯電話" },
  { name: "取引先担当者外部連携id" },
  { name: "有効" },
];

// 日本語から英語へのフィールド名のマッピング
export const _fieldNameMap_contacts: {
  [key: string]: keyof CreateContactInput;
} = {
  "取引先責任者id(編集不可)": "id",
  "姓(必須)": "lastName",
  名: "firstName",
  "取引先id(必須)": "accountId",
  部署: "department",
  Eメール: "email",
  電話番号: "phone",
  携帯電話: "mobilePhone",
  取引先担当者外部連携id: "contactId",
  有効: "active",
};

// importテンプレの列定義を持つ
export const _masterInfo_vendors: Partial<{ name: string }>[] = [
  { name: "仕入先id(編集不可)" },
  { name: "取引先名(必須)" },
  { name: "部門名" },
  { name: "略称" },
  { name: "郵便番号" },
  { name: "都道府県" },
  { name: "市区郡" },
  { name: "町・番地" },
  { name: "電話番号" },
  { name: "FAX" },
  { name: "経理コード" },
  { name: "登録事業者番号" },
  { name: "梱包材生産者登録番号" },
  { name: "仕入先外部連携id" },
  { name: "有効" },
];

// 日本語から英語へのフィールド名のマッピング
export const _fieldNameMap_vendors: {
  [key: string]: keyof CreateVendorInput;
} = {
  "仕入先id(編集不可)": "id",
  "取引先名(必須)": "name",
  部門名: "site",
  略称: "abbreviation",
  郵便番号: "billingPostalCode",
  都道府県: "billingState",
  市区郡: "billingCity",
  "町・番地": "billingStreet",
  電話番号: "phone",
  FAX: "fax",
  経理コード: "accountCode",
  登録事業者番号: "businessRegistrationNumber",
  梱包材生産者登録番号: "packagingMaterialProducerRegistrationNumber",
  仕入先外部連携id: "vendorId",
  有効: "active",
};

export const cutoffDateOptions = [
  "未設定",
  "末日",
  "1日",
  "2日",
  "3日",
  "4日",
  "5日",
  "6日",
  "7日",
  "8日",
  "9日",
  "10日",
  "11日",
  "12日",
  "13日",
  "14日",
  "15日",
  "16日",
  "17日",
  "18日",
  "19日",
  "20日",
  "21日",
  "22日",
  "23日",
  "24日",
  "25日",
  "26日",
  "27日",
  "28日",
  "29日",
];

export const paymentMonthOptions = [
  "未設定",
  "当月",
  "翌月",
  "3ヶ月後",
  "4ヶ月後",
  "5ヶ月後",
  "6ヶ月後",
  "7ヶ月後",
  "8ヶ月後",
  "9ヶ月後",
  "10ヶ月後",
  "11ヶ月後",
  "12ヶ月後",
];
