import { Storage } from "aws-amplify";

export interface S3File {
  key: string;
  lastModified: Date;
}

// base64 decode
export function decodeBase64(fileSrc: string) {
  const blob = atob(fileSrc.replace(/^.*,/, ""));
  let buffer = new Uint8Array(blob.length);
  for (let i = 0; i < blob.length; i++) {
    buffer[i] = blob.charCodeAt(i);
  }
  return buffer;
}

export const downloadFile = async (
  fileName: string,
  data: Blob | MediaSource | string,
  type?: "blob" | "base64"
) => {
  const link = document.createElement("a");
  if (type === "base64") {
    link.href = data as string;
  } else {
    link.href = window.URL.createObjectURL(data as Blob);
  }
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
};

export const deleteFiles = async (fileKeys: string[]) => {
  let delProm: Promise<any>[] = [];
  delProm = fileKeys.map((k: string) => Storage.remove(k));
  await Promise.all(delProm);
};

export const convertDataUrl = async (dataUrl: string) => {
  return await (await fetch(dataUrl)).blob();
};
