import exceljs from "exceljs";

import {
  _masterInfo_accounts,
  _fieldNameMap_accounts,
  _masterInfo_contacts,
  _fieldNameMap_contacts,
  _masterInfo_vendors,
  _fieldNameMap_vendors,
  cutoffDateOptions, // 追加
  paymentMonthOptions,
} from "./cols";

import { GraphQLInput } from "hooks/datalist";

// テンプレートの列情報を作成
const _createTemplateInfo = (
  type: string
): { cols: Partial<exceljs.Column>[] } => {
  const masterInfo =
    type === "accounts"
      ? _masterInfo_accounts
      : type === "contacts"
      ? _masterInfo_contacts
      : type === "vendors"
      ? _masterInfo_vendors
      : [];
  return {
    cols: masterInfo.map((col, index) => {
      //列を非表示にする条件を設定;
      let hidden = false;
      //if (type === "accounts" && col.name === "取引先id") hidden = true;
      if (type === "contacts" && col.name === "取引先責任者id(編集不可)")
        hidden = true;
      else if (type === "vendors" && col.name === "仕入先id(編集不可)")
        hidden = true;

      return {
        header: col.name,
        key: _getAlphabet(index),
        width: 20,
        hidden: hidden, // ここでhiddenプロパティを設定
      };
    }),
  };
};

// 数値をアルファベットに変換
const _getAlphabet = (num: number): string => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return num < 26
    ? alphabet[num]
    : `${alphabet[Math.floor(num / 26) - 1]}${alphabet[num % 26]}`;
};

// Excelで定義された取引先明細をGraphQLInputに変換
export const importTemplate2GraphQLInput = async (
  content: ArrayBuffer,
  type: string
): Promise<GraphQLInput[]> => {
  const workbook = new exceljs.Workbook();
  await workbook.xlsx.load(content);
  const worksheet = workbook.getWorksheet("main");
  const { cols } = _createTemplateInfo(type);
  const rows = worksheet?.getRows(2, 1048576);
  const result: GraphQLInput[] = [];
  if (rows) {
    for (const row of rows) {
      if (row.actualCellCount === 0) break;
      result.push(_row2GraphQLInput(row, cols, type));
    }
  }
  return result;
};

// 行データをGraphQLInputに変換
const _row2GraphQLInput = (
  row: exceljs.Row,
  cols: Partial<exceljs.Column>[],
  type: string
): GraphQLInput => {
  const fieldNameMap =
    type === "accounts"
      ? _fieldNameMap_accounts
      : type === "contacts"
      ? _fieldNameMap_contacts
      : type === "vendors"
      ? _fieldNameMap_vendors
      : {};
  return cols.reduce((acc, col) => {
    const key = col.key as string;
    let cell = row.getCell(key);
    let cellValue = cell.value;

    // セルの値がオブジェクトで、richTextプロパティを持つ場合、そのテキストを取得
    if (cellValue && typeof cellValue === "object" && "richText" in cellValue) {
      cellValue = cellValue.richText.map((part) => part.text).join("");
    } else if (
      cellValue &&
      typeof cellValue === "object" &&
      "text" in cellValue
    ) {
      // ハイパーリンクが含まれている場合、テキストを取得
      cellValue = cellValue.text;
    } else {
      cellValue = cellValue?.toString() ?? "";
    }

    const fieldName = col.header as string;
    const englishFieldName = fieldNameMap[fieldName];
    if (englishFieldName) acc[englishFieldName] = cellValue;
    return acc;
  }, {} as GraphQLInput);
};

// インポートテンプレートを作成
export const createImportTemplate = async (
  templateData: any[],
  type: string
): Promise<Blob> => {
  const workbook = new exceljs.Workbook();
  const worksheet = workbook.addWorksheet("main");
  const { cols } = _createTemplateInfo(type);
  worksheet.columns = cols;

  templateData.forEach((data) => {
    const rowData = cols.map(
      (col) => data[col.header as keyof typeof data] || ""
    );
    worksheet.addRow(rowData);
  });

  // 締日列、支払日列、支払月列にドロップダウンリストを設定
  if (type === "accounts") {
    const cutoffColumnKey = cols.find((col) => col.header === "締日")?.key;
    const paymentDateColumnKey = cols.find(
      (col) => col.header === "支払日"
    )?.key;
    const paymentMonthColumnKey = cols.find(
      (col) => col.header === "支払月"
    )?.key;

    [cutoffColumnKey, paymentDateColumnKey].forEach((key) => {
      if (key) {
        const column = worksheet.getColumn(key);
        column.eachCell((cell, rowNumber) => {
          if (rowNumber > 1) {
            // ヘッダー行を除外
            cell.dataValidation = {
              type: "list",
              allowBlank: true,
              formulae: [`"${cutoffDateOptions.join(",")}"`], // ここにドロップダウンの選択肢を設定
              //showDropDown: true,
            };
          }
        });
      }
    });

    if (paymentMonthColumnKey) {
      const column = worksheet.getColumn(paymentMonthColumnKey);
      column.eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          // ヘッダー行を除外
          cell.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`"${paymentMonthOptions.join(",")}"`], // ここにドロップダウンの選択肢を設定
            //showDropDown: true,
          };
        }
      });
    }
  }

  const buffer = await workbook.xlsx.writeBuffer();
  return new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
};
